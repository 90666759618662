import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import ContractInput from "../Components/ContractInput";
import OfferLetter from "../IssueDocument/OfferLetter";
import ConfidentialityAgreement from "../IssueDocument/ConfidentialityAgreement";
import FulltimeContract from "../IssueDocument/FulltimeContract";
import TemporaryContract from "../IssueDocument/TemporaryContract";
import ParttimeContract from "../IssueDocument/ParttimeContract";
import DailyContract from "../IssueDocument/DailyContract";
import JuvenileContract from "../IssueDocument/JuvenileContract";
import AnnualSalaryContract from "../IssueDocument/AnnualSalaryContract";
import PrivacyConsent from "../IssueDocument/PrivacyConsent";
import ParentalAuthorityConsent from "../IssueDocument/ParentalAuthorityConsent";
import StockOptionContract from "../IssueDocument/StockOptionContract";
import StockholdersAgreement from "../IssueDocument/StockholdersAgreement";
import PartnershipAgreement from "../IssueDocument/PartnershipAgreement";
import Loading from "../Components/Loading";
import NavigationPanel from "../Components/NavigationPanel";

const WB41IssueDocumentSub3 = observer(
  ({
    setSelectedEmployees,
    selectedEmployees,
    setDocument2,
    setDocument3,
    offerLetter,
    setOfferLetter,
    offerLetterInfo,
    setOfferLetterInfo,
    confidentialityAgreement,
    setConfidentialityAgreement,
    confidentialityAgreementInfo,
    setConfidentialityAgreementInfo,
    fulltimeContract,
    fulltimeContractInfo,
    setFulltimeContractInfo,
    setFulltimeContract,
    temporaryContract,
    temporaryContractInfo,
    setTemporaryContractInfo,
    setTemporaryContract,
    parttimeContract,
    parttimeContractInfo,
    setParttimeContractInfo,
    setParttimeContract,
    dailyContract,
    dailyContractInfo,
    setDailyContractInfo,
    setDailyContract,
    juvenileContract,
    juvenileContractInfo,
    setJuvenileContractInfo,
    setJuvenileContract,
    annualSalaryContract,
    annualSalaryContractInfo,
    setAnnualSalaryContractInfo,
    setAnnualSalaryContract,
    privacyConsent,
    privacyConsentInfo,
    setPrivacyConsentInfo,
    setPrivacyConsent,
    stockOptionContract,
    stockOptionContractInfo,
    setStockOptionContractInfo,
    setStockOptionContract,
    stockholdersAgreement,
    stockholdersAgreementInfo,
    setStockholdersAgreementInfo,
    setStockholdersAgreement,
    partnershipAgreement,
    partnershipAgreementInfo,
    setPartnershipAgreementInfo,
    setPartnershipAgreement,
    inputCompanyName,
    setInputCompanyName,
    companyRepresentName,
    setCompanyRepresentName,
    companyAddress,
    setCompanyAddress,
    parentalAuthorityConsentInfo,
    setParentalAuthorityConsentInfo,
    companyTelNumber,
    setCompanyTelNumber,
    corporationRegistrationNumber,
    setCorporationRegistrationNumber,
    selectedEmployeeData,
    setSelectedEmployeeData,
    stockholdersAgreementData,
    setStockholdersAgreementData,
    partnershipAgreementData,
    setPartnershipAgreementData,
    guardianBirthday,
    setGuardianBirthday,
    guardianTelNumber,
    setGuardianTelNumber,
  }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [companyPaymentsStatus, setCompanyPaymentsStatus] = useState();

    useEffect(() => {
      const userData = LoadComapanyData("userData");
      if (userData) {
        setCompanyPaymentsStatus(userData.paymentsStatus);
      } else {
        console.log("No userData found.");
      }
    }, []);

    const onClickDocument = (documentName, employeeData) => {
      if (documentName === "offerLetter") {
        setOfferLetterInfo(true);
      } else if (documentName === "confidentialityAgreement") {
        setConfidentialityAgreementInfo(true);
      } else if (documentName === "annualSalaryContract") {
        setAnnualSalaryContractInfo(true);
      } else if (documentName === "privacyConsent") {
        setPrivacyConsentInfo(true);
      } else if (documentName === "fulltimeContract") {
        setFulltimeContractInfo(true);
      } else if (documentName === "temporaryContract") {
        setTemporaryContractInfo(true);
      } else if (documentName === "parttimeContract") {
        setParttimeContractInfo(true);
      } else if (documentName === "dailyContract") {
        setDailyContractInfo(true);
      } else if (documentName === "juvenileContract") {
        setJuvenileContractInfo(true);
      } else if (documentName === "parentalAuthorityConsent") {
        setParentalAuthorityConsentInfo(true);
      } else if (documentName === "stockOptionContract") {
        setStockOptionContractInfo(true);
      } else if (documentName === "stockholdersAgreement") {
        setStockholdersAgreementInfo(true);
      } else if (documentName === "partnershipAgreement") {
        setPartnershipAgreementInfo(true);
      }
      setSelectedEmployeeData(employeeData);
    };

    const checkOfferLetterData = (employee) => {
      if (!offerLetter) {
        return true;
      }
      return employee?.Documents?.OfferLetter?.Url?.length > 0;
    };

    const checkConfidentialityAgreementData = (employee) => {
      if (!confidentialityAgreement) {
        return true;
      }
      return employee?.Documents?.ConfidentialityAgreement?.Url?.length > 0;
    };

    const checkAnnualSalaryContractData = (employee) => {
      if (!annualSalaryContract) {
        return true;
      }
      return employee?.Documents?.AnnualSalaryContract?.Url?.length > 0;
    };

    const checkPrivacyConsentData = (employee) => {
      if (!privacyConsent) {
        return true;
      }
      return employee?.Documents?.PrivacyConsent?.Url?.length > 0;
    };

    const checkFulltimeContractData = (employee) => {
      if (!fulltimeContract) {
        return true;
      }
      return employee?.Documents?.FulltimeContract?.Url?.length > 0;
    };

    const checkTemporaryContractData = (employee) => {
      if (!temporaryContract) {
        return true;
      }
      return employee?.Documents?.TemporaryContract?.Url?.length > 0;
    };

    const checkParttimeContractData = (employee) => {
      if (!parttimeContract) {
        return true;
      }
      return employee?.Documents?.ParttimeContract?.Url?.length > 0;
    };

    const checkDailyContractData = (employee) => {
      if (!dailyContract) {
        return true;
      }
      return employee?.Documents?.DailyContract?.Url?.length > 0;
    };

    const checkJuvenileContractData = (employee) => {
      if (!juvenileContract) {
        return true;
      }
      return employee?.Documents?.JuvenileContract?.Url?.length > 0;
    };

    const checkParentalAuthorityConsentData = (employee) => {
      if (!juvenileContract) {
        return true;
      }
      return employee?.Documents?.ParentalAuthorityConsent?.Url?.length > 0;
    };

    const checkStockOptionContractData = (employee) => {
      if (!stockOptionContract) {
        return true;
      }
      return employee?.Documents?.StockOptionContract?.Url?.length > 0;
    };

    const checkStockholdersAgreementData = () => {
      if (!stockholdersAgreement) {
        return true;
      }
      return stockholdersAgreementData?.Url?.length > 0;
    };

    const checkPartnershipAgreementData = () => {
      if (!partnershipAgreement) {
        return true;
      }
      return partnershipAgreementData?.Url?.length > 0;
    };

    const generateErrorMessage = (employee) => {
      let errorMessage = "";

      if (!checkOfferLetterData(employee)) {
        errorMessage += "- 입사확약서";
      }
      if (!checkConfidentialityAgreementData(employee)) {
        errorMessage += errorMessage ? "\n- 보안유지서약서" : "- 보안유지서약서";
      }
      if (!checkFulltimeContractData(employee)) {
        errorMessage += errorMessage ? "\n- 정규직 근로계약서" : "- 정규직 근로계약서";
      }
      if (!checkTemporaryContractData(employee)) {
        errorMessage += errorMessage ? "\n- 계약직(기간제) 근로계약서" : "- 계약직(기간제) 근로계약서";
      }
      if (!checkParttimeContractData(employee)) {
        errorMessage += errorMessage ? "\n- 단시간(아르바이트) 근로계약서" : "- 단시간(아르바이트) 근로계약서";
      }
      if (!checkDailyContractData(employee)) {
        errorMessage += errorMessage ? "\n- 일용직 근로계약서" : "- 일용직 근로계약서";
      }
      if (!checkJuvenileContractData(employee)) {
        errorMessage += errorMessage ? "\n- 청소년(연소자) 근로계약서" : "- 청소년(연소자) 근로계약서";
      }
      if (!checkParentalAuthorityConsentData(employee)) {
        errorMessage += errorMessage ? "\n- 친권자(후견인) 동의서" : "- 친권자(후견인) 동의서";
      }
      if (!checkAnnualSalaryContractData(employee)) {
        errorMessage += errorMessage ? "\n- 연봉계약서" : "- 연봉계약서";
      }
      if (!checkPrivacyConsentData(employee)) {
        errorMessage += errorMessage ? "\n- 개인정보 제공 및 수집 이용 동의서" : "- 개인정보 제공 및 수집 이용 동의서";
      }
      if (!checkStockOptionContractData(employee)) {
        errorMessage += errorMessage ? "\n- 주식매수선택권 부여계약서(스톡옵션 계약서)" : "- 주식매수선택권 부여계약서(스톡옵션 계약서)";
      }
      if (!checkStockholdersAgreementData()) {
        errorMessage += errorMessage ? "\n- 주주간계약서" : "- 주주간계약서";
      }
      if (!checkPartnershipAgreementData()) {
        errorMessage += errorMessage ? "\n- 주주간계약서 (동업계약서)" : "- 주주간계약서 (동업계약서)";
      }

      return errorMessage;
    };

    const displayErrorMessages = () => {
      selectedEmployees.forEach((employee) => {
        const errorMessage = generateErrorMessage(employee);
        if (errorMessage) {
          alert(`${employee.Name} 님의 문서에 누락된 정보가 있습니다:\n${errorMessage}`);
        }
      });
    };

    const onClickNext = () => {
      displayErrorMessages();
      const allValid = selectedEmployees.every((employee) => generateErrorMessage(employee) === "");

      if (allValid) {
        setDocument3(false);
        amplitude.track("문서 발급3 - 발급 작성 완료", {
          입사확약서: offerLetter,
          보안유지서약서: confidentialityAgreement,
          정규직근로계약서: fulltimeContract,
          계약직근로계약서: temporaryContract,
          아르바이트근로계약서: parttimeContract,
          일용직근로계약서: dailyContract,
          청소년근로계약서: juvenileContract,
          연봉계약서: annualSalaryContract,
          개인정보활용동의서: privacyConsent,
          스톡옵션계약서: stockOptionContract,
          주주간계약서: stockholdersAgreement,
          동업계약서: partnershipAgreement,
        });
        if (companyPaymentsStatus === true) {
          amplitude.track("동의 받기1 - 동의 문서 선택 스크린 진입");
        } else {
          amplitude.track("동의 받기1 - 결제 안내 스크린 진입");
        }

        navigate("/consentDocument");
      }
    };
    const selectedEmployeeNames = selectedEmployees.map((employee) => employee.Name).join(", ");
    const onClickBack = () => {
      amplitude.track("문서 발급2 - 문서 선택 페이지 진입");
      setAnnualSalaryContractInfo(false);
      setAnnualSalaryContract(false);
      setConfidentialityAgreement(false);
      setConfidentialityAgreementInfo(false);
      setDailyContract(false);
      setDailyContractInfo(false);
      setFulltimeContract(false);
      setFulltimeContractInfo(false);
      setJuvenileContract(false);
      setJuvenileContractInfo(false);
      setOfferLetter(false);
      setOfferLetterInfo(false);

      setParentalAuthorityConsentInfo(false);
      setPartnershipAgreement(false);
      setPartnershipAgreementInfo(false);
      setParttimeContract(false);
      setParttimeContractInfo(false);
      setPrivacyConsent(false);
      setPrivacyConsentInfo(false);
      setStockOptionContract(false);
      setStockOptionContractInfo(false);
      setStockholdersAgreement(false);
      setStockholdersAgreementInfo(false);
      setTemporaryContract(false);
      setTemporaryContractInfo(false);
      setDocument3(false);
      setDocument2(true);
    };

    const userData = LoadComapanyData("userData");

    return (
      <>
        {offerLetterInfo ? (
          <OfferLetter
            setOfferLetterInfo={setOfferLetterInfo}
            inputCompanyName={inputCompanyName}
            setInputCompanyName={setInputCompanyName}
            companyRepresentName={companyRepresentName}
            setCompanyRepresentName={setCompanyRepresentName}
            companyAddress={companyAddress}
            setCompanyAddress={setCompanyAddress}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {confidentialityAgreementInfo ? (
          <ConfidentialityAgreement
            setConfidentialityAgreementInfo={setConfidentialityAgreementInfo}
            inputCompanyName={inputCompanyName}
            companyRepresentName={companyRepresentName}
            setInputCompanyName={setInputCompanyName}
            setCompanyRepresentName={setCompanyRepresentName}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {fulltimeContractInfo ? (
          <FulltimeContract
            setFulltimeContractInfo={setFulltimeContractInfo}
            inputCompanyName={inputCompanyName}
            companyRepresentName={companyRepresentName}
            companyAddress={companyAddress}
            setInputCompanyName={setInputCompanyName}
            setCompanyRepresentName={setCompanyRepresentName}
            setCompanyAddress={setCompanyAddress}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {temporaryContractInfo ? (
          <TemporaryContract
            setTemporaryContractInfo={setTemporaryContractInfo}
            inputCompanyName={inputCompanyName}
            setInputCompanyName={setInputCompanyName}
            companyRepresentName={companyRepresentName}
            setCompanyRepresentName={setCompanyRepresentName}
            companyAddress={companyAddress}
            setCompanyAddress={setCompanyAddress}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {parttimeContractInfo ? (
          <ParttimeContract
            setParttimeContractInfo={setParttimeContractInfo}
            inputCompanyName={inputCompanyName}
            companyRepresentName={companyRepresentName}
            companyAddress={companyAddress}
            setInputCompanyName={setInputCompanyName}
            setCompanyRepresentName={setCompanyRepresentName}
            setCompanyAddress={setCompanyAddress}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {dailyContractInfo ? (
          <DailyContract
            setDailyContractInfo={setDailyContractInfo}
            inputCompanyName={inputCompanyName}
            companyRepresentName={companyRepresentName}
            companyAddress={companyAddress}
            setInputCompanyName={setInputCompanyName}
            setCompanyRepresentName={setCompanyRepresentName}
            setCompanyAddress={setCompanyAddress}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {juvenileContractInfo ? (
          <JuvenileContract
            setJuvenileContractInfo={setJuvenileContractInfo}
            inputCompanyName={inputCompanyName}
            companyRepresentName={companyRepresentName}
            companyAddress={companyAddress}
            setInputCompanyName={setInputCompanyName}
            setCompanyRepresentName={setCompanyRepresentName}
            setCompanyAddress={setCompanyAddress}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {annualSalaryContractInfo ? (
          <AnnualSalaryContract
            setAnnualSalaryContractInfo={setAnnualSalaryContractInfo}
            inputCompanyName={inputCompanyName}
            companyRepresentName={companyRepresentName}
            companyAddress={companyAddress}
            setInputCompanyName={setInputCompanyName}
            setCompanyRepresentName={setCompanyRepresentName}
            setCompanyAddress={setCompanyAddress}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {privacyConsentInfo ? (
          <PrivacyConsent
            setPrivacyConsentInfo={setPrivacyConsentInfo}
            inputCompanyName={inputCompanyName}
            setInputCompanyName={setInputCompanyName}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {parentalAuthorityConsentInfo ? (
          <ParentalAuthorityConsent
            setParentalAuthorityConsentInfo={setParentalAuthorityConsentInfo}
            inputCompanyName={inputCompanyName}
            setInputCompanyName={setInputCompanyName}
            companyRepresentName={companyRepresentName}
            setCompanyRepresentName={setCompanyRepresentName}
            companyTelNumber={companyTelNumber}
            setCompanyTelNumber={setCompanyTelNumber}
            companyAddress={companyAddress}
            setCompanyAddress={setCompanyAddress}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            guardianBirthday={guardianBirthday}
            setGuardianBirthday={setGuardianBirthday}
            guardianTelNumber={guardianTelNumber}
            setGuardianTelNumber={setGuardianTelNumber}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {stockOptionContractInfo ? (
          <StockOptionContract
            setStockOptionContractInfo={setStockOptionContractInfo}
            inputCompanyName={inputCompanyName}
            setInputCompanyName={setInputCompanyName}
            companyAddress={companyAddress}
            setCompanyAddress={setCompanyAddress}
            companyRepresentName={companyRepresentName}
            setCompanyRepresentName={setCompanyRepresentName}
            corporationRegistrationNumber={corporationRegistrationNumber}
            setCorporationRegistrationNumber={setCorporationRegistrationNumber}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            setSelectedEmployees={setSelectedEmployees}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {stockholdersAgreementInfo ? (
          <StockholdersAgreement
            setStockholdersAgreementInfo={setStockholdersAgreementInfo}
            inputCompanyName={inputCompanyName}
            setInputCompanyName={setInputCompanyName}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            stockholdersAgreementData={stockholdersAgreementData}
            setStockholdersAgreementData={setStockholdersAgreementData}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {partnershipAgreementInfo ? (
          <PartnershipAgreement
            setPartnershipAgreementInfo={setPartnershipAgreementInfo}
            inputCompanyName={inputCompanyName}
            setInputCompanyName={setInputCompanyName}
            corporationRegistrationNumber={corporationRegistrationNumber}
            setCorporationRegistrationNumber={setCorporationRegistrationNumber}
            companyAddress={companyAddress}
            setCompanyAddress={setCompanyAddress}
            companyRepresentName={companyRepresentName}
            setCompanyRepresentName={setCompanyRepresentName}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            partnershipAgreementData={partnershipAgreementData}
            setPartnershipAgreementData={setPartnershipAgreementData}
            companyEmail={userData.companyEmail}
            setIsLoading={setIsLoading}
          />
        ) : null}
        {isLoading ? (
          <Loading />
        ) : (
          <div className="AllContent_Default_V2" style={{ marginBottom: "64px" }}>
            <div className="Leave_LeftArea_CT">
              <div className="Leave_Main_Copy">선택하신 문서에 정보를 입력해주세요.</div>
              <div className="Leave_Sub_Copy">문서를 생성하기 위해 기업/기관명, 대표자명 등의 정보가 필요합니다.</div>
              {(offerLetter ||
                confidentialityAgreement ||
                annualSalaryContract ||
                privacyConsent ||
                fulltimeContract ||
                temporaryContract ||
                parttimeContract ||
                dailyContract ||
                juvenileContract ||
                stockOptionContract) &&
                selectedEmployees.map((employee) => (
                  <div className="IssueDocument_DocumentList_CT" key={employee.ID}>
                    <div className="IssueDocument_DocumentList_Label_Copy">{employee.Name} 님의 문서</div>
                    {offerLetter ? (
                      <ContractInput
                        label={"입사확약서"}
                        onpress={() => onClickDocument("offerLetter", employee)}
                        isInputData={checkOfferLetterData(employee)}
                      />
                    ) : null}
                    {confidentialityAgreement ? (
                      <ContractInput
                        label={"보안유지서약서"}
                        onpress={() => onClickDocument("confidentialityAgreement", employee)}
                        isInputData={checkConfidentialityAgreementData(employee)}
                      />
                    ) : null}
                    {annualSalaryContract ? (
                      <ContractInput
                        label={"연봉계약서"}
                        onpress={() => onClickDocument("annualSalaryContract", employee)}
                        isInputData={checkAnnualSalaryContractData(employee)}
                      />
                    ) : null}
                    {privacyConsent ? (
                      <ContractInput
                        label={"개인정보 제공 및 수집 이용 동의서"}
                        onpress={() => onClickDocument("privacyConsent", employee)}
                        isInputData={checkPrivacyConsentData(employee)}
                      />
                    ) : null}
                    {fulltimeContract ? (
                      <ContractInput
                        label={"정규직 근로계약서"}
                        onpress={() => onClickDocument("fulltimeContract", employee)}
                        isInputData={checkFulltimeContractData(employee)}
                      />
                    ) : null}
                    {temporaryContract ? (
                      <ContractInput
                        label={"계약직(기간제) 근로계약서"}
                        onpress={() => onClickDocument("temporaryContract", employee)}
                        isInputData={checkTemporaryContractData(employee)}
                      />
                    ) : null}
                    {parttimeContract ? (
                      <ContractInput
                        label={"단시간(아르바이트) 근로계약서"}
                        onpress={() => onClickDocument("parttimeContract", employee)}
                        isInputData={checkParttimeContractData(employee)}
                      />
                    ) : null}
                    {dailyContract ? (
                      <ContractInput
                        label={"일용직 근로계약서"}
                        onpress={() => onClickDocument("dailyContract", employee)}
                        isInputData={checkDailyContractData(employee)}
                      />
                    ) : null}
                    {juvenileContract ? (
                      <ContractInput
                        label={"청소년(연소자) 근로계약서"}
                        onpress={() => onClickDocument("juvenileContract", employee)}
                        isInputData={checkJuvenileContractData(employee)}
                      />
                    ) : null}
                    {juvenileContract ? (
                      <ContractInput
                        label={"친권자(후견인) 동의서"}
                        onpress={() => onClickDocument("parentalAuthorityConsent", employee)}
                        isInputData={checkParentalAuthorityConsentData(employee)}
                      />
                    ) : null}
                    {stockOptionContract ? (
                      <ContractInput
                        label={"주식매수선택권 부여계약서(스톡옵션 계약서)"}
                        onpress={() => onClickDocument("stockOptionContract", employee)}
                        isInputData={checkStockOptionContractData(employee)}
                      />
                    ) : null}
                  </div>
                ))}
              {stockholdersAgreement || partnershipAgreement ? (
                <div className="IssueDocument_DocumentList_CT">
                  <div className="IssueDocument_DocumentList_Label_Copy">{selectedEmployeeNames} 님의 문서</div>
                  {stockholdersAgreement ? (
                    <ContractInput
                      label={"주주간계약서"}
                      onpress={() => onClickDocument("stockholdersAgreement", selectedEmployees)}
                      isInputData={checkStockholdersAgreementData()}
                    />
                  ) : null}
                  {partnershipAgreement ? (
                    <ContractInput
                      label={"주주간계약서 (동업계약서)"}
                      onpress={() => onClickDocument("partnershipAgreement", selectedEmployees)}
                      isInputData={checkPartnershipAgreementData()}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="Leave_Vertical_Divider" />
            <div className="Leave_RightArea_CT">
              <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"발송"} onClickNext={onClickNext} />
              <div className="Leave_PageCount_Copy">3/4</div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default WB41IssueDocumentSub3;
