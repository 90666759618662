import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { companyStore } from "../State/companyStore";
import { observer } from "mobx-react";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import { AddUnitYYYYMMDDHHMMSS, CalculateNextYear } from "../Utils/ConvertNumberFormat";
import Icon from "../Assets/Icons/Icon";
import Loading from "../Components/Loading";
import DetailTitle from "../Components/DetailTitle";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { v4 as uuidv4 } from "uuid";
import RefundPolicy from "../Components/RefundPolicy";

const WBa1CompanyProfile = observer(() => {
  const navigate = useNavigate();
  const [isLogoOpen, setIsLogoOpen] = useState(false);
  const [isSealOpen, setIsSealOpen] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isVisibleTerms, setIsVisibleTerms] = useState(false);
  const [refundReason, setRefundReason] = useState("");
  const [isRefundReasonInput, setIsRefundReasonInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClickUseTermsLink = async () => {
    window.open("https://certed.co.kr/terms-of-service/ ", "_blank", "noopener noreferrer");
  };
  const handleLogoImage = () => {
    setIsLogoOpen(!isLogoOpen);
  };
  const handleSealImage = () => {
    setIsSealOpen(!isSealOpen);
  };
  const userData = LoadComapanyData("userData");

  const onClickPayment = async () => {
    if (userData.paymentsStatus === true) {
      alert("이미 업그레이드 되어있습니다.");
    } else {
      setIsPayment(true);
    }
  };

  const onClickPaymentClose = () => {
    setIsPayment(false);
  };

  const onClickTermsClose = () => {
    setIsVisibleTerms(false);
    setIsPayment(true);
  };

  const onClickPaymentCancel = async () => {
    setIsRefundReasonInput(true);
  };
  const handleRefundReasonChange = (event) => {
    setRefundReason(event.target.value);
  };
  const updateUserData = () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        delete userData.approvalTime;
        delete userData.cardNumber;
        userData.paymentsStatus = false;
        localStorage.setItem("userData", JSON.stringify(userData));
      } else {
        console.error("No userData found in localStorage");
      }
    } catch (error) {
      console.error("Error updating userData:", error);
    }
  };
  const onClickPaymentCancelConfirm = async () => {
    const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/payments/refund";
    if (refundReason.length > 0) {
      setIsLoading(true);
      setIsRefundReasonInput(false);
      try {
        const response = await axios.post(endPoint, {
          email: userData.companyEmail,
          refundReason: refundReason,
        });
        if (response.data.status === "환불 성공") {
          setIsLoading(false);
          alert("결제가 취소되었습니다. 이용해주셔서 감사합니다.");
          updateUserData();
          navigate("/");
        } else {
          setRefundReason("");
          setIsLoading(false);
          alert("결제 취소를 실패했습니다. 다시 시도해주세요.");
        }
      } catch (error) {
        console.log("PaymentCancelConfirm Error : ", error);
        setRefundReason("");
        setIsLoading(false);
        alert("결제 취소 통신에 실패했습니다. 다시 시도해주세요.");
      }
    } else {
      alert("결제 취소 사유를 입력해주세요.");
    }
  };

  const onClickLandingPage = async () => {
    window.open("https://certed.co.kr", "_blank", "noopener noreferrer");
  };

  const testCustomerKey = uuidv4();
  const handlePayment = () => {
    setIsPayment(false);
    const successUrl = `${window.location.origin}/paymentConfirm`;
    const failUrl = `${window.location.origin}/paymentFail`;
    loadTossPayments(process.env.REACT_APP_TOSSPAYMENTS_CLIENT_KEY).then((tossPayments) => {
      tossPayments
        .requestBillingAuth("카드", {
          // amount: 1000000,
          // orderId: nanoid(),
          // orderName: "써티드 기업회원 연간 이용료",
          // customerEmail: companyEmail,
          // customerName: companyName,
          customerKey: testCustomerKey,
          successUrl: successUrl,
          failUrl: failUrl,
        })
        // .then((data) => {
        //   console.log("data : ", data);
        // })

        .catch(function (error) {
          console.log("Payments error", error);
          if (error.code === "USER_CANCEL") {
            // 결제 고객이 결제창을 닫았을 때 에러 처리
          } else if (error.code === "INVALID_CARD_COMPANY") {
            alert("카드 정보가 유효하지 않습니다.");
          }
        });
    });
  };

  const onClickTerms = async () => {
    setIsPayment(false);
    setIsVisibleTerms(true);
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      {isLogoOpen && (
        <div className="Signin_CompleteModal_BG">
          <div className="Profile_CloseModal_Contents">
            <div className="Profile_CloseModal_Button_CT">
              <button className="Profile_CloseModal_Button" onClick={handleLogoImage}>
                <Icon name={"CloseWhite"} />
              </button>
            </div>

            {companyStore.logo ? (
              <img width={"320px"} src={companyStore.logo} alt="Logo" />
            ) : (
              <div className="Profile_NoImage_Copy">로고 이미지를 등록해주세요.</div>
            )}
          </div>
        </div>
      )}
      {isSealOpen && (
        <div className="Signin_CompleteModal_BG">
          <div className="Profile_CloseModal_Contents">
            <div className="Profile_CloseModal_Button_CT">
              <button className="Profile_CloseModal_Button" onClick={handleSealImage}>
                <Icon name={"CloseWhite"} />
              </button>
            </div>
            <img width={"320px"} src={companyStore.seal} alt="Seal" />
          </div>
        </div>
      )}
      {isRefundReasonInput && (
        <div className="Signin_CompleteModal_BG">
          <div className="Payment_Terms_Modal_CT" style={{ alignItems: "flex-start", width: "320px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className="Payment_Terms_Close_CT" style={{ width: "320px" }}>
                <div className="CheckList_InfoModal_Title_Copy">결제 취소 사유입력</div>
                <div className="CheckList_InfoModal_Icon_CT">
                  <button className="CheckList_Close_Button" onClick={() => setIsRefundReasonInput(false)}>
                    <Icon name={"Close"} size={"24"} />
                  </button>
                </div>
              </div>
              <textarea
                className="Reject_Reason_TextArea2"
                name="etcRejectReason"
                id="etcRejectReason"
                placeholder="결제 취소 사유를 입력해주세요."
                value={refundReason}
                onChange={handleRefundReasonChange}
                style={{ width: "294px" }}
              />
            </div>
            <button className="Login_SigninButton" onClick={onClickUseTermsLink} style={{ marginTop: "12px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="PaymentRefund_Info_Copy">환불 약관</div>
                <Icon name={"ChevronRightBlue"} size={24} />
              </div>
            </button>
            <button
              className="ContractInput_ConfirmButton_BG"
              onClick={onClickPaymentCancelConfirm}
              style={{
                marginTop: "32px",
                width: "320px",
                marginBottom: "0px",
                backgroundColor: "rgb(255, 233, 233)",
                color: "rgb(216, 44, 44)",
              }}
            >
              결제 취소
            </button>
          </div>
        </div>
      )}
      {isVisibleTerms ? (
        <div className="Signin_CompleteModal_BG">
          <div className="Payment_Terms_Modal_CT">
            <div className="Payment_Terms_Close_CT">
              <div className="CheckList_InfoModal_Title_Copy">환불 규정</div>
              <div className="CheckList_InfoModal_Icon_CT">
                <button className="CheckList_Close_Button" onClick={onClickTermsClose}>
                  <Icon name={"Close"} size={24} />
                </button>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "rgb(170,170,170)",
                marginBottom: "16px",
              }}
            ></div>
            <RefundPolicy />
            <button className="Login_SigninButton" onClick={onClickUseTermsLink} style={{ marginTop: "0px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "12px",
                }}
              >
                <div className="PaymentIntro_Info_Body_Copy" style={{ color: "rgb(55, 105, 252)" }}>
                  이용약관 전문
                </div>
                <Icon name={"ChevronRightBlue"} size={24} />
              </div>
            </button>
          </div>
        </div>
      ) : null}
      {isPayment && (
        <div className="Signin_CompleteModal_BG">
          <div className="Payment_Introduce_BG">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%" }}>
              <button className="Profile_CloseModal_Button" onClick={onClickPaymentClose}>
                <Icon name={"Close"} size={"32"} />
              </button>
            </div>
            <div className="PaymentIntro_Info_BG">
              <div className="Read_Item_Name_Copy" style={{ fontWeight: 600, color: "rgb(55, 105, 252)" }}>
                연간 구독권
              </div>
              <div
                className="Leave_Guide_Copy"
                style={{
                  lineHeight: "100%",
                  textAlign: "left",
                  marginTop: "4px",
                }}
              >
                1,000,000원
              </div>
              <div className="PaymentIntro_Info_Divider" />
              <div className="PaymentIntro_Info_CT">
                <div className="PaymentIntro_Info_Label_Copy">직원 인사정보 관리</div>
                <div className="PaymentIntro_Info_Body_Copy">엑셀파일 업로드 한번으로 끝내는 직원관리</div>
              </div>
              <div className="PaymentIntro_Info_CT">
                <div className="PaymentIntro_Info_Label_Copy">증명서 생성</div>
                <div className="PaymentIntro_Info_Body_Copy">등록된 직원정보로 빠르게 생성하는 제증명</div>
              </div>
              <div className="PaymentIntro_Info_CT">
                <div className="PaymentIntro_Info_Label_Copy">입사자 처리</div>
                <div className="PaymentIntro_Info_Body_Copy">처음부터 꼼꼼하게 각종 서류 동의</div>
              </div>
              <div className="PaymentIntro_Info_CT">
                <div className="PaymentIntro_Info_Label_Copy">퇴사자 처리</div>
                <div className="PaymentIntro_Info_Body_Copy">마지막까지 깔끔하게 퇴사 서류 발급</div>
              </div>
              <div className="PaymentIntro_Info_CT" style={{ marginBottom: "0px" }}>
                <div className="PaymentIntro_Info_Label_Copy">앱과 연계</div>
                <div className="PaymentIntro_Info_Body_Copy">웹에서 발급하고 앱에서 확인하는 서류</div>
              </div>
              <button className="Login_SigninButton" onClick={onClickLandingPage} style={{ marginTop: "0px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <div className="PaymentIntro_Info_Body_Copy" style={{ color: "rgb(55, 105, 252)" }}>
                    더 알아보기
                  </div>
                  <Icon name={"ChevronRightBlue"} size={24} />
                </div>
              </button>
              <div className="Login_Button_One_CT" style={{ display: "flex", flexDirection: "column" }}>
                <button className="LoginButton_Copy_CT" onClick={handlePayment}>
                  <div className="LoginButton_One_BG" style={{ width: "280px" }}>
                    <div className="LoginButton_Copy">결제 진행</div>
                  </div>
                </button>
              </div>
            </div>
            <button className="Login_SigninButton" onClick={onClickTerms} style={{ marginTop: "0px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "12px",
                }}
              >
                <div className="PaymentIntro_Info_Body_Copy" style={{ color: "rgb(55, 105, 252)" }}>
                  환불 규정
                </div>
                <Icon name={"ChevronRightBlue"} size={24} />
              </div>
            </button>
          </div>
        </div>
      )}
      <div className="FlexContent_Other_V">
        <DetailTitle title={"기업/기관 프로필"} notUseArrowBack />
        <div className="AllContent_Default_C">
          <div className="Profile_Content">
            <div className="Profile_Label_CT">
              <div
                className="Profile_Label_Copy"
                style={{
                  fontSize: "20px",
                  color: "rgb(55, 105, 252)",
                  marginBottom: "24px",
                }}
              >
                기업/기관 정보
              </div>
            </div>
            <div className="Profile_Subject ">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">기업/기관명</div>
              </div>
              <div className="Profile_Body_Copy">{userData.companyName}</div>
            </div>
            <div className="Profile_Subject_Last ">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">이메일</div>
              </div>
              <div className="Profile_Body_Copy">{userData.companyEmail}</div>
            </div>
            <div className="Profile_Line" />
            <div
              className="Profile_Label_Copy"
              style={{
                fontSize: "20px",
                color: "rgb(55, 105, 252)",
                marginBottom: "24px",
              }}
            >
              구독 정보
            </div>
            {userData?.paymentsStatus ? (
              <>
                <div className="Profile_Subject ">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">카드번호</div>
                  </div>
                  {userData?.cardNumber ? (
                    <div className="Profile_Body_Copy">{userData?.cardNumber}</div>
                  ) : (
                    <div className="Profile_Body_Copy">정보가 존재하지 않습니다.</div>
                  )}
                </div>
                <div className="Profile_Subject_Row ">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "556px",
                    }}
                  >
                    <div className="Profile_Label_CT">
                      <div className="Profile_Label_Copy">결제일자</div>
                    </div>
                    {userData?.approvalTime ? (
                      <div className="Profile_Body_Copy">{AddUnitYYYYMMDDHHMMSS(userData?.approvalTime)}</div>
                    ) : (
                      <div className="Profile_Body_Copy">정보가 존재하지 않습니다.</div>
                    )}
                  </div>
                  <button className="CompanyProfile_PaymentCancel_Button" type="button" onClick={onClickPaymentCancel}>
                    결제 취소
                  </button>
                </div>
                <div className="Profile_Subject ">
                  <div className="Profile_Label_CT">
                    <div className="Profile_Label_Copy">서비스 이용기한</div>
                  </div>
                  {userData?.approvalTime ? (
                    <div className="Profile_Body_Copy">
                      <span style={{ color: "rgb(55, 105, 252)" }}>{`${AddUnitYYYYMMDDHHMMSS(userData?.approvalTime)}`}</span>
                      {" 부터"}
                      <br />
                      <span style={{ color: "rgb(55, 105, 252)" }}>{`${CalculateNextYear(
                        AddUnitYYYYMMDDHHMMSS(userData?.approvalTime)
                      )} 23:59:59`}</span>
                      {"  까지"}
                    </div>
                  ) : (
                    <div className="Profile_Body_Copy">정보가 존재하지 않습니다.</div>
                  )}
                </div>
              </>
            ) : (
              <div className="Profile_Body_Copy">구독 정보가 없습니다.</div>
            )}
            <div className="Profile_Line" />
            <button className="Payment_Button_BG" onClick={onClickPayment}>
              구독 업그레이드
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default WBa1CompanyProfile;
