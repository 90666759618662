import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const CheckListLeft = ({ label, isCheckBox, setIsCheckBox, onpressInfo }) => {
  const onPressCheckBox = () => {
    setIsCheckBox(!isCheckBox);
  };
  return (
    <div className="CheckList_CT" style={{ justifyContent: "flex-start" }}>
      <button type="button" className="TermList_CheckBox_CT" onClick={onPressCheckBox} style={{ marginRight: "12px" }}>
        {isCheckBox === true ? <Icon name={"CheckBoxFill"} size={"32"} /> : <Icon name={"CheckBoxBlank"} size={"32"} />}
      </button>
      <div className="CheckList_Label_CT">
        <div className="CheckList_Label_Copy">{label}</div>
        <button type="button" className="TermList_CheckBox_CT" onClick={onpressInfo}>
          <Icon name={"InfoIcon"} size={"20"} color={"#3769FC"} />
        </button>
      </div>
    </div>
  );
};

export default CheckListLeft;
