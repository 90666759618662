import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import NavigationPanel from "../Components/NavigationPanel";

const WB01SigninSub3 = observer(
  ({
    companyName,
    setCompanyName,
    companyNameDone,
    setCompanyNameDone,
    representativeName,
    setRepresentativeName,
    representativeNameDone,
    setRepresentativeNameDone,
    sealImageSrc,
    setSealImageSrc,
    lisenceSrc,
    setLisenceSrc,
    lisenceDone,
    setLisenceDone,
    resistNumber,
    setResistNumber,
    resistNumberDone,
    setResistNumberDone,
    cleanedResistNumber,
    setCleanedResistNumber,
    sealImageSrcDone,
    setSealImageSrcDone,
    setSignin2,
    setSignin3,
    setSignin4,
  }) => {
    const sealImageInput = useRef();
    const licenseInput = useRef();
    const resistNumberData = {
      b_no: [cleanedResistNumber],
    };

    const checkResistNumber = async (resistNumberData) => {
      try {
        const response = await fetch(
          "https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=YvOh%2BAHuDPJt2pXE6UF1rahmToz%2BhKPxxls7KxGD7%2B9uaTe%2B%2BFZDy9EywpSbUA%2FtT3TLBChFoxWwlLWqQodeJw%3D%3D",
          {
            method: "POST",
            body: JSON.stringify(resistNumberData),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        return result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    useEffect(() => {
      const numberPattern = /^\d+$/;

      if (companyName.length > 0) {
        setCompanyNameDone(true);
      } else {
        setCompanyNameDone(false);
      }

      if (representativeName.length > 0) {
        setRepresentativeNameDone(true);
      } else {
        setRepresentativeNameDone(false);
      }

      if (lisenceSrc) {
        setLisenceDone(true);
      } else {
        setLisenceDone(false);
      }

      if (resistNumber.length > 0) {
        if (numberPattern.test(resistNumber)) {
          const removeNonNumericCharacters = (str) => str.replace(/\D/g, "");
          const cleanedNumber = removeNonNumericCharacters(resistNumber);
          setCleanedResistNumber(cleanedNumber);
          setResistNumberDone(true);
        } else {
          alert("숫자만 입력해주세요.");
          const removeNonNumericCharacters = (str) => str.replace(/\D/g, "");
          const cleanedNumber = removeNonNumericCharacters(resistNumber);
          setResistNumber(cleanedNumber);
          setResistNumberDone(false);
        }
      } else {
        setResistNumberDone(false);
      }

      if (sealImageSrc) {
        setSealImageSrcDone(true);
      } else {
        setSealImageSrcDone(false);
      }
    }, [
      companyName,
      representativeName,
      lisenceSrc,
      resistNumber,
      setCompanyNameDone,
      setRepresentativeNameDone,
      setLisenceDone,
      setCleanedResistNumber,
      setResistNumberDone,
      setResistNumber,
      sealImageSrc,
      setSealImageSrcDone,
    ]);

    const onCickSealImageUpload = async () => {
      sealImageInput.current.click();
    };

    const onCickLicenseUpload = async () => {
      licenseInput.current.click();
    };

    const onSealUpload = (e) => {
      const file = e.target.files[0];
      const maxSize = 5242880;
      if (file && file.size > maxSize) {
        alert("파일 크기는 최대 5MB를 초과할 수 없습니다.");
        e.target.value = null;
      } else {
        setSealImageSrc(e.target.files[0]);
      }
    };

    const onLicenseUpload = (e) => {
      const file = e.target.files[0];
      const maxSize = 5242880;
      if (file && file.size > maxSize) {
        alert("파일 크기는 최대 5MB를 초과할 수 없습니다.");
        e.target.value = null;
      } else {
        setLisenceSrc(e.target.files[0]);
      }
    };

    const onClickBack = () => {
      amplitude.track("회원가입2 - 이메일/비밀번호 등록 페이지 진입");
      setSignin3(false);
      setSignin2(true);
    };

    const onClickNext = async () => {
      const result = await checkResistNumber(resistNumberData);
      let alertMessage = "";

      if (!companyNameDone) {
        alertMessage += "- 기업/기관명을 입력해주세요.\n";
      }
      if (!representativeNameDone) {
        alertMessage += "- 대표자명을 입력해주세요.\n";
      }
      if (!lisenceDone) {
        alertMessage += "- 사업자등록증 파일을 등록해주세요.\n";
      }
      if (!resistNumberDone) {
        alertMessage += "- 사업자등록번호를 입력해주세요.\n";
      }
      if (!sealImageSrcDone) {
        alertMessage += "- 직인 이미지 파일을 등록해주세요.\n";
      }

      if (alertMessage) {
        alert(alertMessage);
      } else {
        if (result.data[0].tax_type !== "국세청에 등록되지 않은 사업자등록번호입니다.") {
          amplitude.track("회원가입3 - 기업/기관 필수 정보 등록 완료", {
            기업기관명: companyName,
            대표명: representativeName,
            직인: sealImageSrc ? sealImageSrc : "직인 미등록",
            사업자등록증: lisenceSrc,
            사업자등록번호: cleanedResistNumber,
            사업자정보객체: result.data[0],
          });
          amplitude.track("회원가입4 - 기업/기관 직원수/업종 정보 등록 페이지 진입");
          setSignin3(false);
          setSignin4(true);
        } else {
          alert(`[${cleanedResistNumber}] 는 국세청에 등록되지 않은 사업자등록번호입니다.\n사업자등록번호를 다시 한번 확인해주세요.`);
        }
      }
    };

    return (
      <>
        <div className="Leave_LeftArea_CT">
          <div className="Leave_Main_Copy">기업/기관의 필수 정보를 입력해주세요.</div>
          <div className="Leave_InputField_CT">
            <div className="ProfileDetail_Subject ">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">기업/기관명</div>
                <div className={companyNameDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>

              <input
                className="Signin_LongInput_BG"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="사업자등록증에 기재된 기업/기관명을 입력해주세요."
                name="companyName"
                required
              />
            </div>
            <div className="ProfileDetail_Subject ">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">대표자명</div>
                <div className={representativeNameDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Signin_LongInput_BG"
                value={representativeName}
                onChange={(e) => setRepresentativeName(e.target.value)}
                placeholder="기업/기관의 대표자명을 모두 입력해주세요."
                name="representativeName"
                required
              />
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">직인</div>
                <div className="Profile_Label_Copy" style={{ fontWeight: 400, marginLeft: 4, fontSize: 14 }}>
                  (png 파일)
                </div>
                <div className={sealImageSrcDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <div className="ProfileDetail_UploadButton_CT" style={{ marginBottom: 8 }}>
                <input className="ProfileDetail_ShortInput_BG " value={sealImageSrc ? sealImageSrc.name : ""} readOnly />
                <input
                  accept=".png"
                  type="file"
                  onChange={(e) => onSealUpload(e)}
                  className="ProfileDetail_UploadButton_None"
                  ref={sealImageInput}
                  max-size="5242880"
                  name="seal"
                />
                <button type="button" className="ProfileDetail_UploadButton" onClick={onCickSealImageUpload}>
                  이미지 업로드
                </button>
              </div>
              <div className="Signin_Match_Copy" style={{ marginTop: 0 }}>
                증명서를 발급할 때 사용되는 직인 파일을 미리 업로드할 수 있습니다
              </div>
            </div>
            <div className="ProfileDetail_Subject">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">사업자등록증</div>
                <div className="Profile_Label_Copy" style={{ fontWeight: 400, marginLeft: 4, fontSize: 14 }}>
                  (pdf, jpg 파일)
                </div>
                <div className={lisenceDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <div className="ProfileDetail_UploadButton_CT">
                <input className="ProfileDetail_ShortInput_BG " value={lisenceSrc ? lisenceSrc.name : ""} readOnly />
                <input
                  accept=".pdf"
                  type="file"
                  onChange={(e) => onLicenseUpload(e)}
                  className="ProfileDetail_UploadButton_None"
                  ref={licenseInput}
                  required
                  max-size="5242880"
                  name="license"
                />
                <button type="button" className="ProfileDetail_UploadButton" onClick={onCickLicenseUpload}>
                  파일 업로드
                </button>
              </div>
            </div>
            <div className="ProfileDetail_Subject ">
              <div className="Profile_Label_CT">
                <div className="Profile_Label_Copy">사업자등록번호</div>
                <div className={resistNumberDone ? "Required_Dot_Blue" : "Required_Dot_Red"} />
              </div>
              <input
                className="Signin_LongInput_BG"
                value={resistNumber}
                onChange={(e) => setResistNumber(e.target.value)}
                placeholder="사업자등록번호를 입력해주세요. (숫자만 입력해주세요.)"
                name="resistNumber"
                required
              />
            </div>
          </div>
        </div>
        <div className="Leave_Vertical_Divider" />
        <div className="Leave_RightArea_CT">
          <NavigationPanel backButtonText={"이전"} onClickBack={onClickBack} nextButtonText={"다음"} onClickNext={onClickNext} />
          <div className="Leave_PageCount_Copy">3/5</div>
        </div>
      </>
    );
  }
);

export default WB01SigninSub3;
