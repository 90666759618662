import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import WB41IssueDocumentSub1 from "../SubPages/WB41IssueDocumentSub1";
import WB41IssueDocumentSub2 from "../SubPages/WB41IssueDocumentSub2";
import WB41IssueDocumentSub3 from "../SubPages/WB41IssueDocumentSub3";

const WB41IssueDocument = observer(() => {
  const [document1, setDocument1] = useState(true);
  const [document2, setDocument2] = useState(false);
  const [document3, setDocument3] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);

  const [offerLetter, setOfferLetter] = useState(false);
  const [offerLetterInfo, setOfferLetterInfo] = useState(false);

  const [confidentialityAgreement, setConfidentialityAgreement] = useState(false);
  const [confidentialityAgreementInfo, setConfidentialityAgreementInfo] = useState(false);

  const [fulltimeContract, setFulltimeContract] = useState(false);
  const [fulltimeContractInfo, setFulltimeContractInfo] = useState(false);

  const [temporaryContract, setTemporaryContract] = useState(false);
  const [temporaryContractInfo, setTemporaryContractInfo] = useState(false);

  const [parttimeContract, setParttimeContract] = useState(false);
  const [parttimeContractInfo, setParttimeContractInfo] = useState(false);

  const [dailyContract, setDailyContract] = useState(false);
  const [dailyContractInfo, setDailyContractInfo] = useState(false);

  const [juvenileContract, setJuvenileContract] = useState(false);
  const [juvenileContractInfo, setJuvenileContractInfo] = useState(false);

  const [parentalAuthorityConsentInfo, setParentalAuthorityConsentInfo] = useState(false);

  const [annualSalaryContract, setAnnualSalaryContract] = useState(false);
  const [annualSalaryContractInfo, setAnnualSalaryContractInfo] = useState(false);

  const [privacyConsent, setPrivacyConsent] = useState(false);
  const [privacyConsentInfo, setPrivacyConsentInfo] = useState(false);

  const [stockOptionContract, setStockOptionContract] = useState(false);
  const [stockOptionContractInfo, setStockOptionContractInfo] = useState(false);

  const [stockholdersAgreement, setStockholdersAgreement] = useState(false);
  const [stockholdersAgreementInfo, setStockholdersAgreementInfo] = useState(false);

  const [partnershipAgreement, setPartnershipAgreement] = useState(false);
  const [partnershipAgreementInfo, setPartnershipAgreementInfo] = useState(false);

  const [inputCompanyName, setInputCompanyName] = useState("");
  const [companyRepresentName, setCompanyRepresentName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyTelNumber, setCompanyTelNumber] = useState("");
  const [corporationRegistrationNumber, setCorporationRegistrationNumber] = useState("");
  const [guardianBirthday, setGuardianBirthday] = useState("");
  const [guardianTelNumber, setGuardianTelNumber] = useState("");

  const [stockholdersAgreementData, setStockholdersAgreementData] = useState();
  const [partnershipAgreementData, setPartnershipAgreementData] = useState();

  const resetSelectedEmployeeInputData = () => {
    setOfferLetter(false);
    setAnnualSalaryContract(false);
    setConfidentialityAgreement(false);
    setDailyContract(false);
    setFulltimeContract(false);
    setJuvenileContract(false);
    setPartnershipAgreement(false);
    setParttimeContract(false);
    setPrivacyConsent(false);
    setStockOptionContract(false);
    setStockholdersAgreement(false);
    setTemporaryContract(false);
    setInputCompanyName("");
    setCompanyRepresentName("");
    setCompanyAddress("");
    setCompanyTelNumber("");
    setCorporationRegistrationNumber("");
    setSelectedEmployees([]);
  };

  return (
    <div className="FlexContent_Other_V">
      <div className="AllContent_Default_C" style={{ alignItems: "center", marginTop: "48px" }}>
        {document1 ? (
          <WB41IssueDocumentSub1
            setDocument1={setDocument1}
            setDocument2={setDocument2}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
          />
        ) : null}
        {document2 ? (
          <WB41IssueDocumentSub2
            setDocument1={setDocument1}
            setDocument2={setDocument2}
            setDocument3={setDocument3}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            resetSelectedEmployeeInputData={resetSelectedEmployeeInputData}
            offerLetter={offerLetter}
            setOfferLetter={setOfferLetter}
            offerLetterInfo={offerLetterInfo}
            setOfferLetterInfo={setOfferLetterInfo}
            confidentialityAgreement={confidentialityAgreement}
            setConfidentialityAgreement={setConfidentialityAgreement}
            confidentialityAgreementInfo={confidentialityAgreementInfo}
            setConfidentialityAgreementInfo={setConfidentialityAgreementInfo}
            fulltimeContract={fulltimeContract}
            setFulltimeContract={setFulltimeContract}
            fulltimeContractInfo={fulltimeContractInfo}
            setFulltimeContractInfo={setFulltimeContractInfo}
            temporaryContract={temporaryContract}
            setTemporaryContract={setTemporaryContract}
            temporaryContractInfo={temporaryContractInfo}
            setTemporaryContractInfo={setTemporaryContractInfo}
            parttimeContract={parttimeContract}
            setParttimeContract={setParttimeContract}
            parttimeContractInfo={parttimeContractInfo}
            setParttimeContractInfo={setParttimeContractInfo}
            dailyContract={dailyContract}
            setDailyContract={setDailyContract}
            dailyContractInfo={dailyContractInfo}
            setDailyContractInfo={setDailyContractInfo}
            juvenileContract={juvenileContract}
            setJuvenileContract={setJuvenileContract}
            juvenileContractInfo={juvenileContractInfo}
            setJuvenileContractInfo={setJuvenileContractInfo}
            annualSalaryContract={annualSalaryContract}
            setAnnualSalaryContract={setAnnualSalaryContract}
            annualSalaryContractInfo={annualSalaryContractInfo}
            setAnnualSalaryContractInfo={setAnnualSalaryContractInfo}
            privacyConsent={privacyConsent}
            setPrivacyConsent={setPrivacyConsent}
            privacyConsentInfo={privacyConsentInfo}
            setPrivacyConsentInfo={setPrivacyConsentInfo}
            parentalAuthorityConsentInfo={parentalAuthorityConsentInfo}
            setParentalAuthorityConsentInfo={setParentalAuthorityConsentInfo}
            stockOptionContract={stockOptionContract}
            setStockOptionContract={setStockOptionContract}
            stockOptionContractInfo={stockOptionContractInfo}
            setStockOptionContractInfo={setStockOptionContractInfo}
            stockholdersAgreement={stockholdersAgreement}
            setStockholdersAgreement={setStockholdersAgreement}
            stockholdersAgreementInfo={stockholdersAgreementInfo}
            setStockholdersAgreementInfo={setStockholdersAgreementInfo}
            partnershipAgreement={partnershipAgreement}
            setPartnershipAgreement={setPartnershipAgreement}
            partnershipAgreementInfo={partnershipAgreementInfo}
            setPartnershipAgreementInfo={setPartnershipAgreementInfo}
          />
        ) : null}
        {document3 ? (
          <WB41IssueDocumentSub3
            setSelectedEmployees={setSelectedEmployees}
            selectedEmployees={selectedEmployees}
            setDocument2={setDocument2}
            setDocument3={setDocument3}
            offerLetter={offerLetter}
            setOfferLetter={setOfferLetter}
            offerLetterInfo={offerLetterInfo}
            setOfferLetterInfo={setOfferLetterInfo}
            confidentialityAgreement={confidentialityAgreement}
            setConfidentialityAgreement={setConfidentialityAgreement}
            confidentialityAgreementInfo={confidentialityAgreementInfo}
            setConfidentialityAgreementInfo={setConfidentialityAgreementInfo}
            fulltimeContract={fulltimeContract}
            fulltimeContractInfo={fulltimeContractInfo}
            setFulltimeContractInfo={setFulltimeContractInfo}
            setFulltimeContract={setFulltimeContract}
            temporaryContract={temporaryContract}
            temporaryContractInfo={temporaryContractInfo}
            setTemporaryContractInfo={setTemporaryContractInfo}
            setTemporaryContract={setTemporaryContract}
            parttimeContract={parttimeContract}
            parttimeContractInfo={parttimeContractInfo}
            setParttimeContractInfo={setParttimeContractInfo}
            setParttimeContract={setParttimeContract}
            dailyContract={dailyContract}
            dailyContractInfo={dailyContractInfo}
            setDailyContractInfo={setDailyContractInfo}
            setDailyContract={setDailyContract}
            juvenileContract={juvenileContract}
            juvenileContractInfo={juvenileContractInfo}
            setJuvenileContractInfo={setJuvenileContractInfo}
            setJuvenileContract={setJuvenileContract}
            annualSalaryContract={annualSalaryContract}
            annualSalaryContractInfo={annualSalaryContractInfo}
            setAnnualSalaryContractInfo={setAnnualSalaryContractInfo}
            setAnnualSalaryContract={setAnnualSalaryContract}
            privacyConsent={privacyConsent}
            privacyConsentInfo={privacyConsentInfo}
            setPrivacyConsentInfo={setPrivacyConsentInfo}
            setPrivacyConsent={setPrivacyConsent}
            stockOptionContract={stockOptionContract}
            stockOptionContractInfo={stockOptionContractInfo}
            setStockOptionContractInfo={setStockOptionContractInfo}
            setStockOptionContract={setStockOptionContract}
            stockholdersAgreement={stockholdersAgreement}
            stockholdersAgreementInfo={stockholdersAgreementInfo}
            setStockholdersAgreementInfo={setStockholdersAgreementInfo}
            setStockholdersAgreement={setStockholdersAgreement}
            partnershipAgreement={partnershipAgreement}
            partnershipAgreementInfo={partnershipAgreementInfo}
            setPartnershipAgreementInfo={setPartnershipAgreementInfo}
            setPartnershipAgreement={setPartnershipAgreement}
            inputCompanyName={inputCompanyName}
            setInputCompanyName={setInputCompanyName}
            companyRepresentName={companyRepresentName}
            setCompanyRepresentName={setCompanyRepresentName}
            companyAddress={companyAddress}
            setCompanyAddress={setCompanyAddress}
            parentalAuthorityConsentInfo={parentalAuthorityConsentInfo}
            setParentalAuthorityConsentInfo={setParentalAuthorityConsentInfo}
            companyTelNumber={companyTelNumber}
            setCompanyTelNumber={setCompanyTelNumber}
            corporationRegistrationNumber={corporationRegistrationNumber}
            setCorporationRegistrationNumber={setCorporationRegistrationNumber}
            selectedEmployeeData={selectedEmployeeData}
            setSelectedEmployeeData={setSelectedEmployeeData}
            stockholdersAgreementData={stockholdersAgreementData}
            setStockholdersAgreementData={setStockholdersAgreementData}
            partnershipAgreementData={partnershipAgreementData}
            setPartnershipAgreementData={setPartnershipAgreementData}
            guardianBirthday={guardianBirthday}
            setGuardianBirthday={setGuardianBirthday}
            guardianTelNumber={guardianTelNumber}
            setGuardianTelNumber={setGuardianTelNumber}
          />
        ) : null}
      </div>
    </div>
  );
});

export default WB41IssueDocument;
