export const AddDotYYYYMMDD = (originalString) => {
  if (originalString?.length !== 8 || "") {
    return originalString;
  }
  const formattedString = originalString.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1.$2.$3");
  return formattedString;
};

export const RemoveDotYYYYMMDD = (originalString) => {
  if (originalString?.length > 0) {
    return originalString.replace(/\./g, "");
  } else {
    return originalString;
  }
};

export const AddUnitYYYYMMDD = (originalString) => {
  if (originalString?.length === 8) {
    const startDateYear = originalString.slice(0, 4);
    const startDateMonth = originalString.slice(4, 6);
    const startDateDay = originalString.slice(6, 8);

    return `${startDateYear}년 ${startDateMonth}월 ${startDateDay}일`;
  } else {
    return "";
  }
};

export const AddUnitYYYYMMDDHHMMSS = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}년 ${month}월 ${day}일 ${hours}:${minutes}:${seconds}`;
};

export const ConvertKSTFormatDate = (inputDate) => {
  const extractDateParts = (dateStr) => {
    const dateParts = dateStr.match(/\d+/g);
    const year = dateParts[0];
    const month = dateParts[1].padStart(2, "0");
    const day = dateParts[2].padStart(2, "0");
    return { year, month, day };
  };
  const date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    const year = date.getUTCFullYear().toString();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${year}.${month}.${day}`;
  } else {
    const { year, month, day } = extractDateParts(inputDate);
    return `${year}.${month}.${day}`;
  }
};

export const AddHyphen13DigitNumber = (numberString) => {
  if (numberString?.length === 13 && !isNaN(numberString)) {
    return `${numberString.slice(0, 6)}-${numberString.slice(6)}`;
  }
  return numberString;
};

export const AddHyphenTelNumber = (numberString) => {
  if (isNaN(numberString)) {
    return numberString;
  }
  const length = numberString?.length;
  if (length === 10) {
    return `${numberString.slice(0, 3)}-${numberString.slice(3, 6)}-${numberString.slice(6)}`;
  } else if (length === 11) {
    return `${numberString.slice(0, 3)}-${numberString.slice(3, 7)}-${numberString.slice(7)}`;
  } else {
    return numberString;
  }
};

export const AddCommaAmountNumber = (numberString) => {
  if (!numberString) {
    return "";
  }
  const onlyNumbers = numberString.replace(/\D/g, "");
  if (onlyNumbers === "") {
    return numberString;
  }
  return onlyNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const CalculateNextYear = (dateString) => {
  const [year, month, day, time] = dateString.split(/년 |월 |일 |:| /).map(Number);
  const originalDate = new Date(year, month - 1, day, time, 0, 0);

  const oneYearLater = new Date(originalDate);
  oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const finalDate = new Date(oneYearLater.getTime() - oneDayInMilliseconds);

  const finalYear = finalDate.getFullYear();
  const finalMonth = String(finalDate.getMonth() + 1).padStart(2, "0");
  const finalDay = String(finalDate.getDate()).padStart(2, "0");

  return `${finalYear}년 ${finalMonth}월 ${finalDay}일`;
};
