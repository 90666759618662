import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const GNBMenuBar = ({ currentPage, currentPageName, onClick, label }) => {
  return (
    <button className={`GNB_MenuBar_BG_${currentPage === currentPageName ? "On" : "Off"}`} type="button" onClick={onClick}>
      <div className="GNB_MenuBar_Contents">
        <Icon
          name={
            label === "직원 등록"
              ? "PersonIcon"
              : label === "문서 작성"
              ? "Certificate"
              : label === "동의 받기"
              ? "ConsentDocument"
              : label === "입사 키트"
              ? "NewEmployeeIcon"
              : label === "퇴사 키트"
              ? "ResignerIcon"
              : label === "문서 조회"
              ? "SearchDocument"
              : label === "직원 조회"
              ? "SearchPersonIcon"
              : label === "회사 정보"
              ? "CompanyBuildingIcon"
              : label === "홈"
              ? "Home"
              : label === "알림"
              ? "Notification"
              : ""
          }
          size={"24"}
          color={currentPage === currentPageName ? "#3769FC" : "#464646"}
        />
        <div className={`GNB_MenuBar_Label_${currentPage === currentPageName ? "On" : "Off"}`}>{label}</div>
      </div>
    </button>
  );
};

export default GNBMenuBar;
