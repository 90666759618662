import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import axios from "axios";
import ReferSelectList from "../Components/ReferSelectList";
import TabBar from "../Components/TabBar";
import { Select } from "react-dropdown-select";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import { DocumentSelectOption } from "../Utils/KeyArray";

const WB83ReferDocumentWaitList = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const itemData = location?.state?.item;
  const [fillteredDocument, setFillteredDocument] = useState(itemData || "");
  const [employeeData, setEmployeeData] = useState([]);
  const [companyEmail, setCompanyEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredDocumentData, setFilteredDocumentData] = useState([]);

  useEffect(() => {
    const userData = LoadComapanyData("userData");
    if (userData) {
      setCompanyEmail(userData.companyEmail);
    } else {
      console.log("No userData found.");
    }
  }, []);

  useEffect(() => {
    if (!companyEmail) return;
    setIsLoading(true);
    const loadEmployeeData = async () => {
      const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/v1/contrack";
      try {
        const response = await axios.get(endPoint, {
          params: { email: companyEmail },
        });
        if (response.data.status === "문서 조회 성공") {
          const rawEmployeeData = response.data.data;
          rawEmployeeData.sort((a, b) => b.CreationDate.localeCompare(a.CreationDate));
          setEmployeeData(rawEmployeeData);
          setIsLoading(false);
        } else {
          alert("직원 정보 조회에 실패하였습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error : ", error);
        alert("직원 정보 조회 통신을 실패하였습니다. 다시 시도해주세요.");
        setIsLoading(false);
      }
    };
    loadEmployeeData();
  }, [companyEmail]);

  useEffect(() => {
    const filteredByStatus = employeeData.filter((employee) => employee.DocumentStatus === "서명대기");
    if (fillteredDocument) {
      const filteredData =
        fillteredDocument === "AllDocument" ? filteredByStatus : filteredByStatus.filter((employee) => employee.DocumentName === fillteredDocument);
      setFilteredDocumentData(filteredData);
    } else {
      setFilteredDocumentData(filteredByStatus);
    }
  }, [fillteredDocument, employeeData]);

  const handleItemClick = (certificateData) => {
    navigate(`/referDocumentDetail/${certificateData.DocumentName}`, {
      state: { item: certificateData, fillter: fillteredDocument, route: "wait" },
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="FlexContent_Other_V" style={{ marginBottom: "192px" }}>
        <div className="AllContent_Default_C">
          <TabBar
            tabLabel1={"referDocumentFullList"}
            tabLink1={"/referDocumentFullList"}
            tabLabel1Text={"전체"}
            tabLabel1AmplitudeText={"문서 조회 (전체) 스크린 진입"}
            tabLabel2={"referDocumentUnsentList"}
            tabLink2={"/referDocumentUnsentList"}
            tabLabel2Text={"미발송"}
            tabLabel2AmplitudeText={"문서 조회 (미발송) 스크린 진입"}
            tabLabel3={"referDocumentWaitList"}
            tabLink3={"/referDocumentWaitList"}
            tabLabel3Text={"대기"}
            tabLabel3AmplitudeText={"문서 조회 (대기) 스크린 진입"}
            tabLabel4={"referDocumentAgreeList"}
            tabLink4={"/referDocumentAgreeList"}
            tabLabel4Text={"완료"}
            tabLabel4AmplitudeText={"문서 조회 (완료) 스크린 진입"}
          />
          <div className="Wait_CT" style={{ alignItems: "flex-start" }}>
            <div style={{ display: "flex", flexDirection: "column", marginBottom: "32px" }}>
              <Select
                options={DocumentSelectOption}
                onChange={(selectedOption) => setFillteredDocument(selectedOption[0].value)}
                values={DocumentSelectOption.filter((option) => option.value === fillteredDocument)}
                style={{
                  width: 320,
                  height: 32,
                  borderRadius: 8,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "rgb(227, 227, 227)",
                  paddingLeft: 12,
                  paddingRight: 12,
                  fontSize: 14,
                }}
              />
            </div>
            {!isLoading && filteredDocumentData.length > 0 ? (
              <ReferSelectList
                labelList={["이름", "전화번호", "문서명", "작성일"]}
                handleItemClick={handleItemClick}
                listData={filteredDocumentData}
              />
            ) : (
              <div className="Wait_NoneItem_Copy">동의 대기 문서가 없습니다.</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default WB83ReferDocumentWaitList;
