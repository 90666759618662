import "./GlobalStyle.css";
import "./PCStyle.css";
import "./TabletStyle.css";
import "./MobileStyle.css";
import "../src/index.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";

import WB01Signin from "./Pages/WB01Signin";
import WB11Login from "./Pages/WB11Login";
import WB21Home from "./Pages/WB21Home";
import WB31AddEmployeeFile from "./Pages/WB31AddEmployeeFile";
import WB32AddEmployeeInfo from "./Pages/WB32AddEmployeeInfo";
import WB41IssueDocument from "./Pages/WB41IssueDocument";
import WB51ConsentDocument from "./Pages/WB51ConsentDocument";
import WB52ConsentDocumentDetail from "./Pages/WB52ConsentDocumentDetail";
import WB61NewEmployee from "./Pages/WB61NewEmployee";
import WB71Resigner from "./Pages/WB71Resigner";
import WB72ResignerList from "./Pages/WB72ResignerList";
import WB73ResignerDetail from "./Pages/WB73ResignerDetail";
import WB81ReferDocumentFullList from "./Pages/WB81ReferDocumentFullList";
import WB82ReferDocumentUnsentList from "./Pages/WB82ReferDocumentUnsentList";
import WB83ReferDocumentWaitList from "./Pages/WB83ReferDocumentWaitList";
import WB84ReferDocumentAgreeList from "./Pages/WB84ReferDocumentAgreeList";
import WB85ReferDocumentRejectList from "./Pages/WB85ReferDocumentRejectList";
import WB86ReferDocumentDetail from "./Pages/WB86ReferDocumentDetail";
import WB91ReferEmployeeList from "./Pages/WB91ReferEmployeeList";
import WB92ReferEmployeeDetail from "./Pages/WB92ReferEmployeeDetail";
import WB93ReferEmployeeModify from "./Pages/WB93ReferEmployeeModify";
import WBa1CompanyProfile from "./Pages/WBa1CompanyProfile";
import WBb1Notification from "./Pages/WBb1Notification";
import PaymentSelect from "./Pages/PaymentSelect";
import PaymentProgress from "./Pages/PaymentProgress";
import PaymentConfirm from "./Pages/PaymentConfirm";
import PaymentFail from "./Pages/PaymentFail";
import GNBVertical from "./Components/GNBVertical";
import ChatBot from "./Assets/Icons/ChatBot";

const App = observer(() => {
  amplitude.init("9fa2d56b8be8c76c4e7a38a6bb76da6f", {
    defaultTracking: false,
    minIdLength: 1,
  });
  return (
    <BrowserRouter>
      <Routes>
        {/* Login과 Signin 페이지는 GNB 없이 */}
        <Route path="/signin" element={<WB01Signin />} />
        <Route path="/" element={<WB11Login />} />
        <Route path="/paymentSelect" element={<PaymentSelect />} />
        <Route path="/paymentProgress" element={<PaymentProgress />} />
        <Route path="/paymentConfirm" element={<PaymentConfirm />} />
        <Route path="/paymentFail" element={<PaymentFail />} />
        {/* 나머지 페이지에는 GNB 포함 */}
        <Route
          path="/*"
          element={
            <>
              <GNBVertical />
              <ChatBot />
              <Routes>
                <Route path="/home" element={<WB21Home />} />
                <Route path="/addEmployeeFile" element={<WB31AddEmployeeFile />} />
                <Route path="/addEmployeeInfo" element={<WB32AddEmployeeInfo />} />
                <Route path="/issueDocument" element={<WB41IssueDocument />} />
                <Route path="/consentDocument" element={<WB51ConsentDocument />} />
                <Route path="/consentDocumentDetail/:id" element={<WB52ConsentDocumentDetail />} />
                <Route path="/newEmployee" element={<WB61NewEmployee />} />
                <Route path="/resigner" element={<WB71Resigner />} />
                <Route path="/resignerList" element={<WB72ResignerList />} />
                <Route path="/resignerDetail/:id" element={<WB73ResignerDetail />} />
                <Route path="/referDocumentFullList" element={<WB81ReferDocumentFullList />} />
                <Route path="/referDocumentUnsentList" element={<WB82ReferDocumentUnsentList />} />
                <Route path="/referDocumentWaitList" element={<WB83ReferDocumentWaitList />} />
                <Route path="/referDocumentAgreeList" element={<WB84ReferDocumentAgreeList />} />
                <Route path="/referDocumentRejectList" element={<WB85ReferDocumentRejectList />} />
                <Route path="/referDocumentDetail/:id" element={<WB86ReferDocumentDetail />} />
                <Route path="/referEmployeeList" element={<WB91ReferEmployeeList />} />
                <Route path="/referEmployeeDetail/:id" element={<WB92ReferEmployeeDetail />} />
                <Route path="/referEmployeeModify/:id" element={<WB93ReferEmployeeModify />} />
                <Route path="/companyProfile" element={<WBa1CompanyProfile />} />
                <Route path="/notification" element={<WBb1Notification />} />
              </Routes>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
});

export default App;
